<template>
	<div class="my-profile">
		<Nav />
		<span class="overlay" :class="{ active: isMenuOpen }"></span>
		<div class="header header-standard">
			<HeaderNavBar back route="MyProfile" />
		</div><!-- header -->
		<div class="header-image grey"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="TechnicalIcon"
				subtitle="My Profile"
				title="Technical Support"
				colour="profile profile-purple"
			/>
			<div class="section-gap">
				<div class="text-block no-space">
					<p>If you find an issue or a bug within the app, please tell us below and we’ll look into it. Thank you.</p>
				</div>
				<form class="form" @submit.prevent="">
					<b-field label-for="technical" label="Tell us what's wrong" :class="{ 'field-error': $v.form.technicalMessage.$error || errorStyling == true }">
			            <b-input placeholder="Explain the technical issue you are experiencing" type="textarea" id="technical" v-model="form.technicalMessage"></b-input>
			        </b-field>
					<div class="field error" v-if="errorMessage">
						<span class="field-error-message">Please make sure all fields are filled in.</span>
					</div>
					<div class="flex-bottom">
						<Button @click.native="sendTechnicalSupport" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Send your message"></Button>
					</div><!-- flex bottom -->
				</form>
			</div>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import { required } from 'vuelidate/lib/validators';
	import Button from '@/components/Button.vue';
    import UserService from '@/services/UserService';
	export default {
		name: 'MyProfileTechnicalSupport',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			Button
		},
		data () {
	        return {
	        	form: {
					technicalMessage: null
				},
                errorMessage: false,
                errorStyling: false
	        };
	    },
	    validations () {
	    	return {
	    		form: {
	            	technicalMessage: {
						required
					}
	           	}
	    	};
	    },
	    methods: {
	    	sendTechnicalSupport () {
		    	this.$v.form.$touch();
	            if (!this.$v.form.$error) {
                    try {
                        UserService.sendTechnicalSupport({
                            issue: this.form.technicalMessage
                        }).then(() => {
                            this.form.technicalMessage = '';
                            alert('Message sent!');
                            this.$router.push('/my-profile');
                        });
                    } catch (error) {
                        this.errorMessage = true;
                        this.errorStyling = true;
                    }
	            }
		    }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {

        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title {
		&.red {
			color: #C83254;
		}
	}
	form {
		margin-top: 30px;
		margin-bottom: 30px;
		.field {
			margin-bottom: 25px;
		}
	}
</style>
